<template>
    <div class="hc18Box">
        <div class="hbBox1" v-if="getAdvData(17).children">
            <a :href="getAdvData(17).children[0].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(17).children[0].image" class="hb1Bg" />
                <div class="h1Box">
                    <div class="h1bLeft">
                        <p class="hl1">智能硬件APP</p>
                        <p class="hl2">
                            智能硬件APP开发解决方案<br />
                            实现硬件与智能APP应用的结合
                        </p>
                        <p class="hl3">提供一站式智能硬件设备iOS、Android开发服务</p>
                    </div>
                    <div class="h1bRight">
                        <img :src="getAdvData(17).children[0].children[0].image" class="hrImg" />
                    </div>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="h2Box">
                <div class="h2b1">
                    <p class="h1T">【智能硬件APP行业解决方案】</p>
                    <ul>
                        <li class="h1Li">
                            <p class="hlp">智能健康</p>
                        </li>
                        <li class="h1Li">
                            <p class="hlp">智能家居</p>
                        </li>
                        <li class="h1Li">
                            <p class="hlp">智能安防</p>
                        </li>
                        <li class="h1Li">
                            <p class="hlp">智能仪器</p>
                        </li>
                        <li class="h1Li">
                            <p class="hlp">智能医疗</p>
                        </li>
                    </ul>
                </div>
                <div class="h2b2">
                    <div class="h2T">智能硬件APP开发服务</div>
                    <div class="h2T2">
                        我们致力于硬件产品的网络化、智能化服务，使之具备网络连接和通讯能力，从而能够实现网络的智能化操作。我们的技术核心不在于电路板与电子编程领域，而是在于实现APP应用逻辑、交互体验和电子模块通讯的技术领域。使这些传统硬件设备联网，与app实现互通，具备智能化操作，这就是智能硬件的整体解决方案，也称为物联网。
                    </div>
                    <ul>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1621.png" alt="" class="hlImg" />
                            <p class="hlp">智能手环</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1622.png" alt="" class="hlImg" />
                            <p class="hlp">智能家居</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1623.png" alt="" class="hlImg" />
                            <p class="hlp">智能家电</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1624.png" alt="" class="hlImg" />
                            <p class="hlp">智能门锁</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1625.png" alt="" class="hlImg" />
                            <p class="hlp">智能衣柜</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1626.png" alt="" class="hlImg" />
                            <p class="hlp">智能锁</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1627.png" alt="" class="hlImg" />
                            <p class="hlp">智能手表</p>
                        </li>
                        <li class="h2Li">
                            <img src="http://iv.okvu.cn/vugw/img/hc1628.png" alt="" class="hlImg" />
                            <p class="hlp">智能插座</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Box">
                <div class="h3T">智能硬件连接技术</div>
                <ul>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1629.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">蓝牙双模技术</p>
                        <p class="hl3">
                            手机APP通过蓝牙4.0即BLE技术与智能终端连接，实现低功率、高效率的数据通讯；通过蓝牙2.0技术实现音频输出，两者可同时连接同时运作，统称蓝牙双模。同时支持空中烧录，即远程更新主控单片机。
                        </p>
                    </li>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1630.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">NFC近场通讯</p>
                        <p class="hl3">
                            硬件终端内置NFC模块就可以和配备了 NFC功能的手机实现近距离通讯，或者通过NFC实现交通卡等功能
                        </p>
                    </li>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc16301.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">2.4G无线控制</p>
                        <p class="hl3">双向高速数据传输，具有快速跳频，前向纠错，校验等功能。</p>
                    </li>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc16302.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">超音频控制</p>
                        <p class="hl3">
                            利用手机自带的扬声器，通过APP发送介于高频和中频之间的频率信号，设备通过解析信号来获得动作执行指令。极大的降低了通讯的硬件成本。
                        </p>
                    </li>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc16303.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">2G/3G/4G/物联卡通讯</p>
                        <p class="hl3">
                            通过智能终端中物联卡，就可以通过APP远程监控智能终端，专门适应于无WIFI环境的室外场地使用。
                        </p>
                    </li>
                    <li class="h3Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc16304.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">WIFI通讯模块</p>
                        <p class="hl3">
                            通过在智能终端中内置Wi-Fi模块，就可以通过APP远程控制智能终端。WIFI通讯模块分为：通用Wi-Fi模块、路由器方案Wi-Fi模块、嵌入式Wi-Fi模块。
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Box">
                <div class="h4T">智能硬件APP主要功能</div>
                <ul>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1632.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">电源控制</p>
                        <p class="hl3">可以遥控或定时开关智能家居设备</p>
                    </li>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1633.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">灯光影音控制</p>
                        <p class="hl3">可以遥控或定时开关智能家居设备</p>
                    </li>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1634.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">指纹密码锁控制</p>
                        <p class="hl3">运用多种方式遥控具有语音识别功能的远程控制器来实现开锁功能</p>
                    </li>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1635.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">人脸识别</p>
                        <p class="hl3">家居光照条件下多角度的人脸检测和识别</p>
                    </li>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1636.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">手势识别</p>
                        <p class="hl3">动静态手势识别率高，操控只需举手之劳</p>
                    </li>
                    <li class="h4Li">
                        <div class="hl1">
                            <img src="http://iv.okvu.cn/vugw/img/hc1637.png" alt="" class="h1Icon" />
                        </div>
                        <p class="hl2">安防报警功能</p>
                        <p class="hl3">通过网络摄像机，实时监控家庭安全状况</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5T">
                <p class="ht1">设计展示</p>
                <p class="ht2">智能门锁APP，可以智能生成房门锁秘钥，打造智能、快捷、安全门锁</p>
            </div>
            <div class="hb5Bg">
                <img src="http://iv.okvu.cn/vugw/img/hc1638.png" alt="" class="hgImg" />
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb6Box">
                <div class="h6bTop">我们的优势</div>
                <div class="h6bList">
                    <ul>
                        <li class="h6lLi">
                            <div class="hl1">
                                <img src="http://iv.okvu.cn/vugw/img/hc1639.png" alt="" class="h1Icon" />
                            </div>
                            <p class="hl2">多方案的物联网技术</p>
                            <p class="hl3">
                                通过射频识别(RFID)、红外感应器、全球定位系统、激光扫描器等信息传感设备，按约定的协议，将任何物品与互联网相连接
                            </p>
                        </li>
                        <li class="h6lLi">
                            <div class="hl1">
                                <img src="http://iv.okvu.cn/vugw/img/hc1640.png" alt="" class="h1Icon" />
                            </div>
                            <p class="hl2">共创共赢的云平台</p>
                            <p class="hl3">对接能力提供商，支持二次开发、自由定制、多维度运营</p>
                        </li>
                        <li class="h6lLi">
                            <div class="hl1">
                                <img src="http://iv.okvu.cn/vugw/img/hc16401.png" alt="" class="h1Icon" />
                            </div>
                            <p class="hl2">完备的技术体系</p>
                            <p class="hl3">拥有信号处理、LoRa无线传输、平台服务、系统集成等完备的核心技术</p>
                        </li>
                        <li class="h6lLi">
                            <div class="hl1">
                                <img src="http://iv.okvu.cn/vugw/img/hc16402.png" alt="" class="h1Icon" />
                            </div>
                            <p class="hl2">全面的解决方案</p>
                            <p class="hl3">提供完整的软硬一体的可编程化、智能化的解决方案</p>
                        </li>
                        <li class="h6lLi">
                            <div class="hl1">
                                <img src="http://iv.okvu.cn/vugw/img/hc16403.png" alt="" class="h1Icon" />
                            </div>
                            <p class="hl2">多平台硬件方案</p>
                            <p class="hl3">适配不同芯片平台和操作系统，提供从软件到整体设计的多种方案</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox7">
            <div class="hb7Box">
                <div class="h7bTop">客户案例</div>
                <div class="h7bList">
                    <ul>
                        <li class="h7lLi" v-for="item in list" :key="item.id" @click.prevent="goDetail(item)">
                            <img v-lazy="item.little_image" class="hlBg" />
                            <div class="h7Mask" v-if="item.qrcode || item.logo">
                                <img v-if="item.qrcode" v-lazy="item.qrcode" class="hmCode" />
                                <img v-else v-lazy="item.logo" class="hmCode" />
                                <!-- <p class="hmP">{{ item.title }}</p> -->
                            </div>
                        </li>
                    </ul>
                </div>
                <button class="h7bbtn" @click="$router.push('/case')">更多案例</button>
            </div>
        </div>
        <div class="hbBox8">
            <div class="hb5Box">
                <div class="h5Top">
                    <div class="h5t1">业务展示</div>
                    <div class="h5t2">专业团队，品质服务</div>
                </div>
                <div class="h5List">
                    <ul>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>
                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4451.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">智能硬件</p>
                                <p class="h5p2">Intelligent hardware</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">品牌展示网站</li>
                                    <li class="h53Li">官方门户网站</li>
                                    <li class="h53Li">企业营销网站</li>
                                    <li class="h53Li">电子商务平台</li>
                                    <li class="h53Li">信息门户系统</li>
                                    <li class="h53Li">集团站群系统</li>
                                    <li class="h53Li">3D虚拟仿真</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>

                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4451.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">网站建设</p>
                                <p class="h5p2">Website building</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">品牌展示网站</li>
                                    <li class="h53Li">官方门户网站</li>
                                    <li class="h53Li">企业营销网站</li>
                                    <li class="h53Li">电子商务平台</li>
                                    <li class="h53Li">信息门户系统</li>
                                    <li class="h53Li">集团站群系统</li>
                                    <li class="h53Li">3D虚拟仿真</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>

                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4452.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">移动端</p>
                                <p class="h5p2">Mobile end</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">手机网站</li>
                                    <li class="h53Li">APP开发</li>
                                    <li class="h53Li">自适应网站</li>
                                    <li class="h53Li">响应式网站</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>

                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4453.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">小程序</p>
                                <p class="h5p2">Applets</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">O2O场景化</li>
                                    <li class="h53Li">社群社交</li>
                                    <li class="h53Li">生活服务</li>
                                    <li class="h53Li">工具应用</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>

                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4454.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">服务号/订阅号</p>
                                <p class="h5p2">Wechat marketing</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">微官网</li>
                                    <li class="h53Li">微商城</li>
                                    <li class="h53Li">微活动</li>
                                    <li class="h53Li">微预定</li>
                                    <li class="h53Li">微支付</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <div class="h5Icon"></div>

                                <!-- <img src="http://iv.okvu.cn/vugw/img/hc4455.png" alt="" class="h5Icon" /> -->
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">企业微信</p>
                                <p class="h5p2">Business number</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">团队协作</li>
                                    <li class="h53Li">CRM</li>
                                    <li class="h53Li">企业文化</li>
                                    <li class="h53Li">移动办公</li>
                                    <li class="h53Li">行政办公</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HsContent18",
    inject: ["getAdvData"],
    data() {
        return {
            list: [],
        };
    },
    async created() {
        await this.getData();
    },
    methods: {
        async getData() {
            const { data } = await axios.get("api/project/homeHardware");
            this.list = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hc18Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        height: 540px;
        position: relative;
        overflow: hidden;
        .hb1Bg {
            // width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            object-fit: cover;
            // top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        .h1Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .h1bLeft {
                width: 387px;
                height: 244px;
                float: left;
                color: white;
                margin-top: 125px;
                .hl1 {
                    width: 100%;
                    height: 64px;
                    line-height: 64px;
                    font-size: 48px;
                    font-weight: bold;
                }
                .hl2 {
                    width: 100%;
                    height: 72px;
                    font-size: 18px;
                    line-height: 36px;
                }
                .hl3 {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    margin-top: 20px;
                }
            }
            .h1bRight {
                width: 812px;
                height: 480px;
                float: left;
                .hrImg {
                    width: 100%;
                    height: 100%;
                    transition: 0.5s;
                }
                .hrImg:hover {
                    transform: translateX(20px);
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 836px;
        margin-top: 50px;
        .h2Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .h2b1 {
                width: 100%;
                height: 204px;
                .h1T {
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    font-weight: bold;
                    font-size: 28px;
                }
                ul {
                    width: 100%;
                    height: 117px;
                    margin-top: 50px;
                    display: flex;
                    justify-content: space-around;
                    position: relative;
                    .h1Li {
                        width: 105px;
                        height: 105px;
                        position: relative;
                        cursor: pointer;
                        text-align: center;
                        color: #fff;
                        border-radius: 5px;
                        border-radius: 50%;
                        background: #7781f1;
                        z-index: 4;
                        .hlp {
                            width: 100%;
                            height: 105px;
                            font-size: 18px;
                            // margin-top: 30px;
                            line-height: 105px;
                            white-space: nowrap;
                        }
                    }
                }
                ul::after {
                    position: absolute;
                    z-index: 1;
                    top: 52px;
                    right: 50%;
                    width: 85%;
                    height: 1px;
                    content: "";
                    background: #7781f1;
                    transform: translate(50%, 0);
                }
            }
            .h2b2 {
                width: 100%;
                height: 632px;
                margin-top: 70px;
                .h2T {
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    font-size: 28px;
                    font-weight: bold;
                }
                .h2T2 {
                    width: 100%;
                    height: 108px;
                    font-size: 18px;
                    margin-top: 20px;
                    color: #666666;
                }
                ul {
                    width: 100%;
                    height: 377px;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin-top: 50px;
                    .h2Li {
                        width: 300px;
                        height: 138px;
                        cursor: pointer;
                        transition: 0.5s;
                        .hlImg {
                            width: 90px;
                            height: 90px;
                            display: block;
                            margin: 0 auto;
                            transition: 0.5s;
                        }
                        .hlp {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            text-align: center;
                            font-size: 18px;
                            margin-top: 20px;
                        }
                    }
                    .h2Li:hover {
                        .hlImg {
                            transform: translateY(-20px);
                        }
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 870px;
        background-color: #f9f9f9;
        .hb3Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h3T {
                width: 100%;
                height: 37px;
                line-height: 37px;
                font-size: 28px;
                text-align: center;
                font-weight: bold;
                margin-top: 50px;
            }
            ul {
                width: 100%;
                height: 776px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                margin-top: 50px;
                .h3Li {
                    width: 400px;
                    height: 338px;
                    cursor: pointer;
                    .hl1 {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #7781f1;
                        overflow: hidden;
                        margin: 0 auto;
                        transition: 0.5s;
                        .h1Icon {
                            margin: 0 auto;
                            margin-top: 20px;
                            display: block;
                        }
                    }
                    .hl2 {
                        width: 100%;
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        font-size: 24px;
                        margin-top: 20px;
                    }
                    .hl3 {
                        width: 373px;
                        height: 160px;
                        line-height: 30px;
                        margin-top: 20px;
                        text-align: center;
                    }
                }
                .h3Li:hover {
                    .hl1 {
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 700px;
        background-color: #7781f1;
        color: white;
        .hb4Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h4T {
                width: 100%;
                height: 37px;
                line-height: 37px;
                font-size: 28px;
                text-align: center;
                font-weight: bold;
                margin-top: 50px;
            }
            ul {
                width: 100%;
                height: 590px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                margin-top: 80px;
                .h4Li {
                    width: 400px;
                    height: 205px;
                    text-align: center;
                    cursor: pointer;

                    .hl1 {
                        width: 100%;
                        height: 105px;
                        transition: 0.5s;
                        .h1Icon {
                            width: 105px;
                            height: 105px;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .hl2 {
                        width: 100%;
                        height: 35px;
                        line-height: 35px;
                        font-size: 22px;
                    }
                    .hl3 {
                        width: 100%;
                        min-height: 65px;
                        line-height: 22px;
                    }
                }
                .h4Li:hover {
                    .hl1 {
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 1346px;
        overflow: hidden;
        .hb5T {
            width: 90%;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
            .ht1 {
                width: 100%;
                height: 37px;
                line-height: 37px;
                font-size: 28px;
                font-weight: bold;
            }
            .ht2 {
                width: 100%;
                height: 36px;
                line-height: 36px;
                font-size: 18px;
                margin-top: 20px;
                color: #666666;
            }
        }
        .hb5Bg {
            width: 100%;
            height: 1093px;
            margin-top: 80px;
            .hgImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 522px;
        background-color: #f9f9f9;
        .hb6Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h6bTop {
                width: 100%;
                height: 45px;
                line-height: 45px;
                text-align: center;
                font-size: 34px;
                margin-top: 30px;
            }
            .h6bList {
                width: 100%;
                height: 347px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .h6lLi {
                        width: 240px;
                        height: 100%;
                        text-align: center;
                        transition: 0.5s;
                        cursor: pointer;
                        .hl1 {
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            background-color: #7781f1;
                            margin: 0 auto;
                            overflow: hidden;
                            .h1Icon {
                                width: 70px;
                                height: 70px;
                                display: block;
                                margin: 0 auto;
                                margin-top: 40px;
                            }
                        }
                        .hl2 {
                            width: 210px;
                            height: 85px;
                            line-height: 85px;
                            margin: 0 auto;
                        }
                        .hl3 {
                            width: 210px;
                            height: 112px;
                            font-size: 14px;
                            margin: 0 auto;
                        }
                    }
                    .h6lLi:hover {
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
    .hbBox7 {
        width: 100%;
        height: 850px;
        .hb7Box {
            width: 1173px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h7bbtn {
                width: 127px;
                height: 40px;
                margin: 0 auto;
                margin-top: 10px;
                font-size: 15px;
                line-height: 40px;
                text-align: center;
                border-radius: 25px;
                color: white;
                background-color: #7882f1;
                border: none;
                cursor: pointer;
            }
            .h7bTop {
                width: 100%;
                height: 45px;
                line-height: 45px;
                text-align: center;
                font-size: 34px;
                font-weight: bold;
                margin-top: 70px;
            }
            .h7bList {
                width: 100%;
                height: 571px;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .h7lLi {
                        width: 373px;
                        height: 255px;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        cursor: pointer;
                        .hlBg {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .h7Mask {
                            width: 100%;
                            height: 100%;
                            background-color: rgba(92, 91, 91, 0.5);
                            opacity: 0;
                            transition: 0.5s;
                            position: absolute;
                            top: 0;
                            overflow: hidden;
                            .hmCode {
                                width: 120px;
                                height: 120px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                // margin: 0 auto;
                                // display: block;
                                // margin-top: 80px;
                                border-radius: 10px;
                            }
                            .hmP {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                margin-top: 20px;
                                text-align: center;
                                font-weight: bold;
                                color: white;
                            }
                        }
                    }
                    .h7lLi:hover {
                        .h7Mask {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .hbBox8 {
        width: 100%;
        height: 730px;
        background: #f1f1f1;
        .hb5Box {
            width: 1423px;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            .h5Top {
                width: 100%;
                text-align: center;
                margin-top: 50px;
                .h5t1 {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    font-size: 36px;
                }
                .h5t2 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 15px;
                    color: #999999;
                }
            }
            .h5List {
                width: 100%;
                height: 560px;
                margin: 0 auto;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    .h5lLi:nth-of-type(1) {
                        .h5Icon {
                            background-position: 0px -175px;
                        }
                    }
                    .h5lLi:nth-of-type(2) {
                        .h5Icon {
                            background-position: -112px -175px;
                        }
                    }
                    .h5lLi:nth-of-type(3) {
                        .h5Icon {
                            width: 42px !important;
                            background-position: -215px -175px;
                        }
                    }
                    .h5lLi:nth-of-type(4) {
                        .h5Icon {
                            width: 60px !important;
                            background-position: -317px -175px;
                        }
                    }
                    .h5lLi:nth-of-type(5) {
                        .h5Icon {
                            width: 65px !important;
                            background-position: -373px -175px;
                        }
                    }
                    .h5lLi:nth-of-type(6) {
                        .h5Icon {
                            width: 60px !important;
                            background-position: -439px -175px;
                        }
                    }
                    .h5lLi {
                        width: 16.6%;
                        height: 100%;
                        text-align: center;
                        float: left;
                        background-color: #fff;
                        transition: 0.3s;
                        position: relative;
                        .h5l1 {
                            width: 100%;
                            height: 86px;
                            margin-top: 50px;
                            .h5Icon {
                                display: inline-block;
                                width: 100px;
                                height: 100%;
                                transition: 0.3s;
                                background-image: url("https://iv.okvu.cn/vugw/img/vu1212.png");
                                // background-color: yellow;
                            }
                        }
                        .h5l2 {
                            width: 100%;
                            margin-top: 20px;
                            transition: 0.3s;
                            .h5p1 {
                                width: 100%;
                                height: 24px;
                                line-height: 24px;
                                font-size: 24px;
                            }
                            .h5p2 {
                                width: 100%;
                                height: 15px;
                                line-height: 15px;
                                font-size: 15px;
                                color: #dddddd;
                                margin-top: 10px;
                            }
                        }
                        .h5l3 {
                            width: 78px;
                            min-height: 34px;
                            margin: 0 auto;
                            margin-top: 50px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                .h53Li {
                                    width: 100%;
                                    height: 34px;
                                    line-height: 34px;
                                    text-align: center;
                                    list-style-type: disc;
                                    color: #585858;
                                    font-size: 13px;
                                    cursor: pointer;
                                }
                                .h53Li:hover {
                                    color: #999999;
                                }
                            }
                        }
                    }
                    .h5lLi:nth-child(1) {
                        border-top-left-radius: 20px;
                        border-bottom-left-radius: 20px;
                        margin-left: 5px;
                    }
                    .h5lLi:nth-child(6) {
                        border-top-right-radius: 20px;
                        border-bottom-right-radius: 20px;
                    }
                    .h5lLi:nth-child(n + 2) {
                        border-left: 1px solid #f6f6f6;
                        box-sizing: border-box;
                    }
                    .h5lLi:hover {
                        z-index: 10;
                        box-shadow: 0 0 59px rgba(0, 0, 0, 0.07);
                        .h5Icon {
                            transform: scale(1.1);
                        }
                        .h5l2 {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}
</style>
